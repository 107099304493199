import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTutoringUser } from "../shared/auth/TutoringContext";

function StripeCallback() {
    const [status, setStatus] = useState("Processing...");
    const navigate = useNavigate();
    const { userInfo } = useTutoringUser();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        const stateParam = urlParams.get("state");

        // Check for required data
        if (!userInfo || !userInfo.uuid) {
            setStatus("User information missing. Please log in and try again.");
            return;
        }

        const uuid = userInfo.uuid;
        const email = stateParam || userInfo.email;

        if (code && email && uuid) {
            setStatus("Connecting your Stripe account...");

            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/tutoring-api/stripe-connect`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ code, email, uuid }),
                },
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            `HTTP error! Status: ${response.status}`,
                        );
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Stripe connect response:", data);

                    if (data.success) {
                        setStatus("Successfully connected!");
                        setTimeout(() => navigate("/tutoring/my-info"), 2000);
                    } else {
                        setStatus(
                            `Connection failed: ${data.error || "Unknown error"}`,
                        );
                    }
                })
                .catch((error) => {
                    console.error("Error connecting to Stripe:", error);
                    setStatus("Connection failed. Please try again.");
                });
        } else if (code) {
            // We have a code but missing other data
            setStatus("Missing user information. Please try again.");
        }
    }, [navigate, userInfo]);

    return (
        <div className="tutoring-container">
            <h1 className="tutoring-title">Connecting to Stripe</h1>
            <p>{status}</p>
        </div>
    );
}

export default StripeCallback;
