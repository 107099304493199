import React, { useState, useEffect } from "react";
import GenericAsync from "../shared/backend/GenericAsync";
import PayoutsTable from "./payouts/PayoutsTable";
import PayoutsSummary from "./payouts/PayoutsSummary";
import "./payouts/Payouts.css";
import { useTutoringUser } from "../shared/auth/TutoringContext";

function Payouts() {
    const { userInfo } = useTutoringUser();
    const [paginatedPayouts, setPaginatedPayouts] = useState(null);
    const [allPayouts, setAllPayouts] = useState(null);

    const [isPaginatedPayoutsLoading, setIsPaginatedPayoutsLoading] =
        useState(false);
    const [isAllPayoutsLoading, setIsAllPayoutsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [filteredPayouts, setFilteredPayouts] = useState([]);
    const payoutsPerPage = 10;
    const [shouldRefresh, setShouldRefresh] = useState(false);

    // Set default dates: January 1st of current year and current date
    const currentDate = new Date();
    const defaultStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
    ); // First day of current month

    const formatDateForState = (date) => {
        return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    };

    const [startDate, setStartDate] = useState(
        formatDateForState(defaultStartDate),
    );
    const [endDate, setEndDate] = useState(formatDateForState(currentDate));

    // Handle date input changes
    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setCurrentPage(1); // Reset to first page when date changes
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        setCurrentPage(1); // Reset to first page when date changes
    };

    // Function to handle refresh button click
    const handleRefresh = () => {
        setShouldRefresh((prev) => !prev); // Toggle the refresh state
    };

    const findPayoutsInDateRange = () => {
        if (allPayouts) {
            setIsPaginatedPayoutsLoading(true);

            // Create start date at beginning of day (00:00:00) in local timezone
            const startDay = new Date(startDate + "T00:00:00");
            const startTimestamp = Math.floor(startDay.getTime() / 1000);

            // Create end date at end of day (23:59:59) in local timezone
            const endDay = new Date(endDate + "T23:59:59");
            const endTimestamp = Math.floor(endDay.getTime() / 1000);

            // Filter but preserve original indices from allPayouts
            const payoutsWithIndices = allPayouts
                .map((payout, index) => ({
                    payout,
                    originalIndex: index,
                }))
                .filter((item) => {
                    const payoutTimestamp = item.payout.created;
                    return (
                        payoutTimestamp >= startTimestamp &&
                        payoutTimestamp <= endTimestamp
                    );
                });

            // Extract just the payouts for the filtered list
            const payoutsInRange = payoutsWithIndices.map(
                (item) => item.payout,
            );

            setFilteredPayouts(payoutsInRange);
        }
    };

    // Function to fetch payout data
    const fetchPaginatedPayouts = () => {
        if (filteredPayouts) {
            let indexOfEndPayout = (currentPage - 1) * payoutsPerPage;
            let indexOfStartPayout = indexOfEndPayout + payoutsPerPage;

            let endDateTimestamp;
            let startDateTimestamp;

            // Get the current page payouts (10 per page)
            const currentPagePayouts = filteredPayouts.slice(
                indexOfEndPayout,
                indexOfStartPayout,
            );

            if (currentPagePayouts.length > 0) {
                // Get the next payout after the max index (if it exists)
                if (indexOfStartPayout < filteredPayouts.length) {
                    // There is a next payout, use its date
                    const nextPayout = filteredPayouts[indexOfStartPayout];
                    startDateTimestamp = nextPayout.created;
                } else {
                    // No next payout, use the start of day for start date
                    const startDay = new Date(startDate);
                    startDay.setHours(0, 0, 0, 0);
                    startDateTimestamp = Math.floor(startDay.getTime() / 1000);
                }

                endDateTimestamp = filteredPayouts[indexOfEndPayout].created;

                GenericAsync({
                    backendUrl: `${process.env.REACT_APP_BACKEND_URL}/tutoring-api/get-paginated-payouts`,
                    dataToSend: {
                        userEmail: userInfo?.email,
                        organization: userInfo?.organization,
                        startDate: startDateTimestamp,
                        endDate: endDateTimestamp,
                    },
                    method: "POST",
                })
                    .then((data) => {
                        setPaginatedPayouts(data.grouped_data);
                        setIsPaginatedPayoutsLoading(false);
                    })
                    .catch((error) => {
                        console.error(
                            "Error fetching paginated payouts:",
                            error,
                        );
                        setIsPaginatedPayoutsLoading(false);
                    });
            } else {
                // No payouts in range
                setPaginatedPayouts([]);
                setIsPaginatedPayoutsLoading(false);
            }
        }
    };

    const handleNextPage = () => {
        if (currentPage * payoutsPerPage < filteredPayouts.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const fetchAllPayouts = () => {
        if (userInfo?.email) {
            setIsAllPayoutsLoading(true);

            GenericAsync({
                backendUrl: `${process.env.REACT_APP_BACKEND_URL}/tutoring-api/get-all-payouts`,
                dataToSend: {
                    userEmail: userInfo?.email,
                    organization: userInfo?.organization,
                },
                method: "POST",
            })
                .then((data) => {
                    // Sort payouts by created date in descending order (newest first)
                    const sortedPayouts =
                        data.payouts && Array.isArray(data.payouts)
                            ? [...data.payouts].sort((a, b) => {
                                  // Check if created property exists on both objects
                                  if (a.created && b.created) {
                                      return b.created - a.created; // Descending order
                                  }
                                  return 0; // No valid dates to compare
                              })
                            : [];

                    setAllPayouts(sortedPayouts);
                    setIsAllPayoutsLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching all payouts:", error);
                    setIsAllPayoutsLoading(false);
                });
        }
    };

    // Fetch data when component mounts
    useEffect(() => {
        fetchAllPayouts();
    }, [userInfo?.email, userInfo?.organization]);

    // Fetch paginated data only when refresh is triggered or all payouts change
    useEffect(() => {
        fetchPaginatedPayouts();
    }, [currentPage, filteredPayouts, shouldRefresh]);

    useEffect(() => {
        findPayoutsInDateRange();
    }, [allPayouts, shouldRefresh]);

    return (
        <div className="tutoring-container">
            <h1 className="tutoring-title">Payouts</h1>

            <div className="date-selector">
                <div className="date-input-group">
                    <label htmlFor="start-date">Start Date:</label>
                    <input
                        type="date"
                        id="start-date"
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                </div>

                <div className="date-input-group">
                    <label htmlFor="end-date">End Date:</label>
                    <input
                        type="date"
                        id="end-date"
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </div>

                <button
                    className="refresh-button"
                    onClick={handleRefresh}
                    aria-label="Refresh data"
                >
                    Refresh
                </button>
            </div>

            {isAllPayoutsLoading ? (
                <p>Loading summary data...</p>
            ) : (
                <PayoutsSummary
                    allPayouts={allPayouts}
                    dateRangeStart={startDate}
                    dateRangeEnd={endDate}
                />
            )}

            {isPaginatedPayoutsLoading ? (
                <p>Loading your payout records...</p>
            ) : (
                <>
                    <PayoutsTable records={paginatedPayouts || []} />

                    {filteredPayouts.length > payoutsPerPage ? (
                        <div className="pagination-controls">
                            <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                className="pagination-button"
                            >
                                Previous
                            </button>
                            <span className="pagination-info">
                                Page {currentPage} of{" "}
                                {Math.ceil(
                                    filteredPayouts.length / payoutsPerPage,
                                )}
                            </span>
                            <button
                                onClick={handleNextPage}
                                disabled={
                                    currentPage * payoutsPerPage >=
                                    filteredPayouts.length
                                }
                                className="pagination-button"
                            >
                                Next
                            </button>
                        </div>
                    ) : (
                        <div style={{ paddingBottom: "60px" }}></div>
                    )}
                </>
            )}
        </div>
    );
}

export default Payouts;
