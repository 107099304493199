import React, { useState, useRef } from "react";
import GenericAsync from "../backend/GenericAsync";
import { useError } from "../errors/ErrorContext";
import { useSettings } from "./SettingsContext";
import TermsAndConditionsText from "../utils/TermsAndConditionsText";
import "../../website/css/TermsAndConditions.css";

const TermsAndConditions = () => {
    const { settings, setSettings, isModalOpen } = useSettings();
    const [isEnabled, setIsEnabled] = useState(false);
    const termsRef = useRef(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { triggerError } = useError();

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            setIsEnabled(true); // Enable the button when scrolled to the bottom
        }
    };

    const handleSubmit = async () => {
        const dataToSend = {
            userEmail: settings?.userEmail,
        };

        try {
            await GenericAsync({
                backendUrl: `${backendUrl}/api/submit-terms-and-conditions`,
                dataToSend: dataToSend,
            });
        } catch (error) {
            triggerError(error.message);
        }

        setSettings({ ...settings, termsAcknowledged: true });
    };

    if (!settings || isModalOpen || settings.termsAcknowledged) {
        return null;
    }

    return (
        <>
            <div className="terms-popup-overlay"></div>
            <div className="terms-popup-container">
                <div
                    className="terms-popup-content"
                    ref={termsRef}
                    onScroll={handleScroll}
                >
                    <div className="terms-header">
                        <h2 className="terms-title">Terms and Conditions</h2>
                    </div>
                    <TermsAndConditionsText />
                    <button
                        className="terms-agree-button"
                        onClick={handleSubmit}
                        disabled={!isEnabled}
                    >
                        Agree
                    </button>
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions;
