import styles from "../css/AdminStyles.module.css";
import { format } from "date-fns";

// Constants
const SUBJECT_OPTIONS = ["sat", "act", "general"];
const STATUS_OPTIONS = [
    "scheduled",
    "completed",
    "client_no_show",
    "late_cancel",
];
const ORGANIZATION_OPTIONS = ["kindred", "alia"];
const TRANSFER_TYPE_OPTIONS = ["fee", "tutor_payment", "profit_split"];

// Helper function for time options
const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const h = hour.toString().padStart(2, "0");
            const m = minute.toString().padStart(2, "0");
            const time = `${h}:${m}`;
            const label = format(new Date(`2000-01-01T${time}`), "h:mm aa");
            options.push(
                <option key={time} value={time}>
                    {label}
                </option>,
            );
        }
    }
    return options;
};

// Helper to create renderers with display names
const createRenderer = (name, renderFn) => {
    renderFn.displayName = name;
    return renderFn;
};

// Email selector renderer (used for tutor_email, client_email)
export const emailSelectorRenderer = (options, isMultiple = false) => {
    const renderer = (
        rowData,
        field,
        { isEditing, handleInputChange, newRow, editingRow },
    ) => {
        const value = rowData.isNew
            ? newRow?.[field] || (isMultiple ? [] : "")
            : isEditing
              ? editingRow[field] || (isMultiple ? [] : "")
              : rowData[field] || (isMultiple ? [] : "");

        if (!isEditing) {
            return <div>{isMultiple ? value[0] || "" : value}</div>;
        }

        const currentValue = isMultiple ? value[0] || "" : value;
        const placeholder = `Select ${field.includes("client") ? "client" : "tutor"}...`;

        const renderEmailSelector = () => (
            <select
                className={styles.emailSelect}
                value=""
                onChange={(e) => {
                    if (e.target.value) {
                        handleInputChange(
                            rowData,
                            field,
                            isMultiple ? [e.target.value] : e.target.value,
                        );

                        if (isMultiple) {
                            e.target.value = "";
                        }
                    }
                }}
            >
                <option value="">{placeholder}</option>
                {(options || [])
                    .filter((item) => item.email !== currentValue)
                    .map((item) => (
                        <option
                            key={`${item.email} (${item.first_name} ${item.last_name}, ${item.organization ? item.organization.charAt(0).toUpperCase() + item.organization.slice(1) : ""})`}
                            value={item.email}
                        >
                            {item.email} ({item.first_name} {item.last_name},{" "}
                            {item.organization
                                ? item.organization.charAt(0).toUpperCase() +
                                  item.organization.slice(1)
                                : ""}
                            )
                        </option>
                    ))}
            </select>
        );

        const renderSelectedEmail = () => (
            <div className={styles.selectedEmailTag}>
                <span className={styles.emailText}>{currentValue}</span>
                <button
                    className={styles.removeEmailBtn}
                    onClick={() =>
                        handleInputChange(rowData, field, isMultiple ? [] : "")
                    }
                >
                    ×
                </button>
            </div>
        );

        return (
            <div className={styles.emailSelectorContainer}>
                {currentValue ? renderSelectedEmail() : renderEmailSelector()}
            </div>
        );
    };

    renderer.displayName = `EmailSelector(${isMultiple ? "Multiple" : "Single"})`;
    return renderer;
};

// UUID renderer (read-only)
export const uuidRenderer = createRenderer(
    "UUIDRenderer",
    (rowData, field, { isEditing }) => {
        if (!isEditing) {
            return <div>{rowData[field]}</div>;
        }
        // For new or edited rows, show UUID but make it read-only
        return (
            <input
                type="text"
                value={rowData[field]}
                readOnly
                style={{
                    width: "90%",
                    backgroundColor: "#f5f5f5",
                    cursor: "not-allowed",
                }}
            />
        );
    },
);

// Reporting emails array renderer
export const reportingEmailsRenderer = createRenderer(
    "ReportingEmailsRenderer",
    (rowData, field, { handleInputChange, isEditing, newRow, editingRow }) => {
        const value = rowData.isNew
            ? newRow?.[field] || [] // Provide empty array fallback
            : isEditing
              ? editingRow[field] || []
              : rowData[field] || [];

        if (!isEditing) {
            return (
                <div className={styles.arrayCell}>
                    {value.map((email) => (
                        <span key={email} className={styles.tag}>
                            {email}
                        </span>
                    ))}
                </div>
            );
        }

        return (
            <div className={styles.emailSelectorContainer}>
                {value.map((email) => (
                    <div key={email} className={styles.selectedEmailTag}>
                        {email}
                        <span
                            className={styles.removeEmail}
                            onClick={() => {
                                const updatedEmails = value.filter(
                                    (e) => e !== email,
                                );
                                handleInputChange(
                                    rowData,
                                    field,
                                    updatedEmails,
                                );
                            }}
                        >
                            ×
                        </span>
                    </div>
                ))}
                <input
                    type="text"
                    placeholder="Type email and press Enter..."
                    className={styles.emailInput}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && e.target.value) {
                            e.preventDefault();
                            const newEmail = e.target.value.trim();
                            if (newEmail && !value.includes(newEmail)) {
                                const updatedEmails = [...value, newEmail];
                                handleInputChange(
                                    rowData,
                                    field,
                                    updatedEmails,
                                );
                                e.target.value = "";
                            }
                        }
                    }}
                />
            </div>
        );
    },
);

// Subjects array renderer
export const subjectsRenderer = (
    rowData,
    field,
    { handleInputChange, isEditing, newRow, editingRow },
) => {
    const value = rowData.isNew
        ? newRow?.[field] || []
        : isEditing
          ? editingRow[field] || []
          : rowData[field] || [];

    if (!isEditing) {
        return (
            <div className={styles.arrayCell}>
                {value.map((subject) => (
                    <span key={subject} className={styles.tag}>
                        {subject.toUpperCase()}
                    </span>
                ))}
            </div>
        );
    }

    return (
        <div className={styles.subjectsCell}>
            <div className={styles.subjectsDisplay}>
                {value.map((subject) => (
                    <span key={subject} className={styles.subjectTag}>
                        {subject.toUpperCase()}
                        <span
                            className={styles.removeSubject}
                            onClick={() => {
                                const updatedSubjects = value.filter(
                                    (s) => s !== subject,
                                );
                                handleInputChange(
                                    rowData,
                                    field,
                                    updatedSubjects,
                                );
                            }}
                        >
                            ×
                        </span>
                    </span>
                ))}
            </div>
            <select
                className={styles.subjectsSelect}
                onChange={(e) => {
                    if (e.target.value) {
                        const updatedSubjects = [...value, e.target.value];
                        handleInputChange(rowData, field, updatedSubjects);
                        e.target.value = "";
                    }
                }}
            >
                <option value="">Add subject...</option>
                {SUBJECT_OPTIONS.filter(
                    (subject) => !value.includes(subject),
                ).map((subject) => (
                    <option key={subject} value={subject}>
                        {subject.toUpperCase()}
                    </option>
                ))}
            </select>
        </div>
    );
};

// Organization renderer with dropdown options
export const organizationRenderer = createRenderer(
    "OrganizationRenderer",
    (rowData, field, { handleInputChange, isEditing, newRow, editingRow }) => {
        const value = rowData.isNew
            ? newRow?.[field] || ""
            : isEditing
              ? editingRow[field] || ""
              : rowData[field] || "";

        // Display capitalized version when not editing
        if (!isEditing) {
            const capitalizedValue =
                value.charAt(0).toUpperCase() + value.slice(1);
            return <div>{capitalizedValue}</div>;
        }

        // When editing, use dropdown with predefined options
        return (
            <div className={styles.organizationCell}>
                <select
                    className={styles.organizationSelect}
                    value={value}
                    onChange={(e) =>
                        handleInputChange(rowData, field, e.target.value)
                    }
                >
                    <option value=""></option>
                    {ORGANIZATION_OPTIONS.map((org) => (
                        <option key={org} value={org}>
                            {org.charAt(0).toUpperCase() + org.slice(1)}
                        </option>
                    ))}
                </select>
            </div>
        );
    },
);

// Base date/time renderer with error handling
export const dateTimeRenderer = (
    rowData,
    field,
    { handleInputChange, isEditing, newRow, editingRow },
    additionalUI = null,
) => {
    // Get browser's timezone for explicit use
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const value = rowData.isNew
        ? newRow?.[field]
        : isEditing
          ? editingRow[field]
          : rowData[field];

    if (!isEditing) {
        return value ? (
            <div>{format(new Date(value), "MMM d, yyyy h:mm aa")}</div>
        ) : null;
    }

    // For editing, always show in browser's local time
    let localDate;
    try {
        localDate = value ? new Date(value) : new Date();
        // Validate date is correct
        if (isNaN(localDate.getTime())) throw new Error("Invalid date");
    } catch (error) {
        console.warn("Invalid date value:", value);
        localDate = new Date(); // Fallback to current date
    }

    // Format date string from local browser date - keep in local timezone
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;

    // Get hours and minutes in local timezone
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");
    const timeString = `${hours}:${minutes}`;

    const handleDateChange = (e) => {
        try {
            const dateValue = e.target.value;
            if (!dateValue) return;

            // Create date in local time zone
            const [year, month, day] = dateValue.split("-").map(Number);
            const newDate = new Date(localDate);
            newDate.setFullYear(year, month - 1, day);

            // Keep time the same
            handleDateTimeChange(newDate);
        } catch (error) {
            console.warn("Error setting date:", error);
        }
    };

    const handleTimeChange = (e) => {
        try {
            const timeValue = e.target.value;
            if (!timeValue) return;

            const [hours, minutes] = timeValue.split(":").map(Number);
            const newDate = new Date(localDate);
            newDate.setHours(hours, minutes);

            handleDateTimeChange(newDate);
        } catch (error) {
            console.warn("Error setting time:", error);
        }
    };

    const handleDateTimeChange = (newDate) => {
        try {
            // Create a proper UTC ISO string with timezone information
            const utcString = newDate.toISOString().replace("Z", "+00:00");
            // Log timezone information for debugging
            // console.log(`Using timezone: ${userTimezone}, local date: ${newDate}, UTC: ${utcString}`);

            handleInputChange(rowData, field, utcString);
        } catch (error) {
            console.warn("Error converting date:", error);
        }
    };

    return (
        <div className={styles.dateTimeContainer}>
            <input
                type="date"
                value={dateString}
                onChange={handleDateChange}
                className={styles.dateTimeInput}
            />
            <select
                value={timeString}
                onChange={handleTimeChange}
                className={styles.dateTimeInput}
            >
                <option value="">Select time</option>
                {generateTimeOptions()}
            </select>
            {additionalUI}
        </div>
    );
};

// End date/time renderer with quick buttons that extends base renderer
export const endDateTimeRenderer = (rowData, field, contextObj) => {
    const quickButtons = (
        <div className={styles.quickTimeButtons}>
            {[1, 2, 3].map((hour) => (
                <button
                    key={hour}
                    onClick={() => {
                        if (!rowData.start_datetime) return;
                        try {
                            // Get browser's timezone
                            const userTimezone =
                                Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone;

                            // Start with browser's local time
                            const startLocalDate = new Date(
                                rowData.start_datetime,
                            );
                            const endLocalDate = new Date(startLocalDate);
                            endLocalDate.setHours(
                                endLocalDate.getHours() + hour,
                            );
                            endLocalDate.setMinutes(
                                Math.round(endLocalDate.getMinutes() / 15) * 15,
                            );

                            // Convert to proper UTC format with timezone information
                            const utcString = endLocalDate
                                .toISOString()
                                .replace("Z", "+00:00");

                            // Log timezone information for debugging
                            // console.log(`Quick button: Using timezone: ${userTimezone}, local: ${endLocalDate}, UTC: ${utcString}`);

                            contextObj.handleInputChange(
                                rowData,
                                field,
                                utcString,
                            );
                        } catch (error) {
                            console.warn("Error setting quick time:", error);
                        }
                    }}
                    type="button"
                    className={styles.quickTimeButton}
                >
                    +{hour}h
                </button>
            ))}
        </div>
    );

    return dateTimeRenderer(rowData, field, contextObj, quickButtons);
};

// Status renderer
export const statusRenderer = (
    rowData,
    field,
    { handleInputChange, isEditing, newRow, editingRow },
) => {
    const value = rowData.isNew
        ? newRow?.[field]
        : isEditing
          ? editingRow[field]
          : rowData[field];

    const formatValue = (val) => {
        return val
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    if (!isEditing) {
        return (
            <div className={styles.arrayCell}>
                {value && (
                    <span className={styles.tag}>{formatValue(value)}</span>
                )}
            </div>
        );
    }

    return (
        <div className={styles.statusCell}>
            <select
                value={value || ""}
                onChange={(e) =>
                    handleInputChange(rowData, field, e.target.value)
                }
                className={styles.statusSelect}
            >
                <option value="">Select status...</option>
                {STATUS_OPTIONS.map((status) => (
                    <option key={status} value={status}>
                        {formatValue(status)}
                    </option>
                ))}
            </select>
            <span className={styles.dropdownIcon}>▼</span>
        </div>
    );
};

// Payment split JSON renderer
export const paymentSplitRenderer = createRenderer(
    "PaymentSplitRenderer",
    (rowData, field, { handleInputChange, isEditing, newRow, editingRow }) => {
        const value = rowData.isNew
            ? newRow?.[field] || {
                  organizations: {},
                  referrers: {},
                  conditional: {},
              }
            : isEditing
              ? editingRow[field] || {
                    organizations: {},
                    referrers: {},
                    conditional: {},
                }
              : rowData[field] || {
                    organizations: {},
                    referrers: {},
                    conditional: {},
                };

        // For display mode, show a formatted summary
        if (!isEditing) {
            // Helper function to format splits
            const formatSplits = (entity, data, isConditional = false) => {
                if (!data || typeof data !== "object") return "";

                if (isConditional) {
                    // Handle nested conditional structure
                    const details = data[entity] || {};
                    if (!details) return "";

                    const splitType = details.split_type || "unknown";
                    const amountType = details.amount_type || "unknown";
                    const amount = details.amount || 0;

                    return `${entity}: ${splitType} ${amountType === "percent" ? amount + "%" : "$" + amount}`;
                } else {
                    // Regular splits structure (organizations, referrers)
                    const splits = data[entity] || [];
                    if (!Array.isArray(splits)) return "";

                    return splits
                        .map((split) => {
                            const splitType = split.split_type || "unknown";
                            const amountType = split.amount_type || "unknown";
                            const amount = split.amount || 0;

                            return `${entity}: ${splitType} ${amountType === "percent" ? amount + "%" : "$" + amount}`;
                        })
                        .join(", ");
                }
            };

            // Format organizations
            const orgSplits = Object.keys(value.organizations || {})
                .map((org) => formatSplits(org, value.organizations))
                .filter(Boolean)
                .join("; ");

            // Format referrers
            const refSplits = Object.keys(value.referrers || {})
                .map((email) => formatSplits(email, value.referrers))
                .filter(Boolean)
                .join("; ");

            // Format conditional - different structure
            const condSplits = Object.keys(value.conditional || {})
                .map((condKey) => {
                    const condSection = value.conditional[condKey];
                    return Object.keys(condSection || {})
                        .map((entity) =>
                            formatSplits(entity, condSection, true),
                        )
                        .filter(Boolean)
                        .join(", ");
                })
                .filter(Boolean)
                .join("; ");

            // Reusable component for displaying sections
            const SplitSection = ({ label, content }) =>
                content ? (
                    <div className={styles.splitSectionRow}>
                        <strong>{label}:</strong>
                        <div className={styles.cellContent}>{content}</div>
                    </div>
                ) : null;

            return (
                <div className={styles.paymentSplitDisplay}>
                    <SplitSection label="Org" content={orgSplits} />
                    <SplitSection label="Ref" content={refSplits} />
                    <SplitSection label="Cond" content={condSplits} />
                </div>
            );
        }

        // For edit mode, use a textarea with JSON
        return (
            <textarea
                value={JSON.stringify(
                    {
                        organizations: value.organizations || {},
                        referrers: value.referrers || {},
                        conditional: value.conditional || {},
                    },
                    null,
                    2,
                )}
                onChange={(e) => {
                    try {
                        const parsed = JSON.parse(e.target.value);
                        // Ensure the structure has organizations and referrers
                        const validated = {
                            organizations: parsed.organizations || {},
                            referrers: parsed.referrers || {},
                            conditional: parsed.conditional || {},
                        };
                        handleInputChange(rowData, field, validated);
                    } catch (error) {
                        console.log(
                            "Invalid JSON - will not update until valid",
                        );
                    }
                }}
                placeholder={`{
                    "organizations": {
                        "alia": [
                        {
                            "split_type": "fee",
                            "amount_type": "percent",
                            "amount": 3
                        },
                        {
                            "split_type": "profit_split",
                            "amount_type": "percent",
                            "amount": 50
                        }
                        ]
                    },
                    "referrers": {
                        "email@example.com": [
                        {
                            "split_type": "profit_split",
                            "amount_type": "percent",
                            "amount": 50
                        }
                        ]
                    }
                }`}
                rows={8}
                className={styles.jsonTextarea}
            />
        );
    },
);

// Boolean renderer with dropdown
export const booleanRenderer = createRenderer(
    "BooleanRenderer",
    (rowData, field, { handleInputChange, isEditing, newRow, editingRow }) => {
        const value = rowData.isNew
            ? newRow?.[field] !== undefined
                ? newRow[field]
                : false
            : isEditing
              ? editingRow[field] !== undefined
                  ? editingRow[field]
                  : false
              : rowData[field] !== undefined
                ? rowData[field]
                : false;

        // Display Yes/No when not editing
        if (!isEditing) {
            return <div>{value ? "True" : "False"}</div>;
        }

        // When editing, use dropdown with true/false options
        return (
            <div className={styles.organizationCell}>
                <select
                    className={styles.organizationSelect}
                    value={value.toString()}
                    onChange={(e) =>
                        handleInputChange(
                            rowData,
                            field,
                            e.target.value === "true",
                        )
                    }
                >
                    <option value="true">True</option>
                    <option value="false">False</option>
                </select>
            </div>
        );
    },
);

// Custom renderer for relationship UUID dropdown
export const relationshipRenderer = (relationships = []) => {
    const renderer = (
        rowData,
        field,
        { isEditing, handleInputChange, newRow, editingRow },
    ) => {
        const value = rowData.isNew
            ? newRow?.[field] || ""
            : isEditing
              ? editingRow[field] || ""
              : rowData[field] || "";

        const tutorEmail = rowData.isNew
            ? newRow?.tutor_email || ""
            : isEditing
              ? editingRow.tutor_email || rowData.tutor_email || ""
              : rowData.tutor_email || "";

        // Handle client email which could be a string or an array
        const clientEmail = rowData.isNew
            ? newRow?.client_email || ""
            : isEditing
              ? editingRow.client_email || rowData.client_email || ""
              : rowData.client_email || "";

        // Get the actual email string regardless of format
        const clientEmailStr = Array.isArray(clientEmail)
            ? clientEmail[0] || ""
            : clientEmail;

        // Filter relationships based on tutor and client emails
        const filteredRelationships = relationships.filter(
            (rel) =>
                rel.tutor_email === tutorEmail &&
                rel.client_email === clientEmailStr,
        );

        if (!isEditing) {
            // Find the relationship to display its description
            const relationship = relationships.find(
                (rel) => rel.uuid === value,
            );
            return (
                <div>
                    {relationship
                        ? `${relationship.description || relationship.uuid}`
                        : value}
                </div>
            );
        }

        return (
            <div className={styles.relationshipSelectorContainer}>
                <select
                    className={styles.emailSelect}
                    value={value}
                    onChange={(e) => {
                        const selectedUuid = e.target.value;
                        handleInputChange(rowData, field, selectedUuid);

                        // Auto-fill rates if a relationship is selected
                        if (selectedUuid) {
                            const selectedRelationship = relationships.find(
                                (rel) => rel.uuid === selectedUuid,
                            );
                            if (selectedRelationship) {
                                handleInputChange(
                                    rowData,
                                    "client_rate",
                                    selectedRelationship.client_rate,
                                );
                                handleInputChange(
                                    rowData,
                                    "tutor_rate",
                                    selectedRelationship.tutor_rate,
                                );
                            }
                        }
                    }}
                    disabled={!tutorEmail || !clientEmailStr}
                >
                    <option value="">Select relationship...</option>
                    {filteredRelationships.map((rel) => (
                        <option key={rel.uuid} value={rel.uuid}>
                            {rel.description || rel.uuid}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    renderer.displayName = "RelationshipRenderer";
    return renderer;
};

// Read-only renderer
export const readOnlyRenderer = (capitalize = false) =>
    createRenderer("ReadOnlyRenderer", (rowData, field) => {
        const value = rowData[field];

        // Handle boolean values
        if (typeof value === "boolean") {
            return <div>{value ? "true" : "false"}</div>;
        }

        // Existing functionality for other types
        const formatValue = (val) => {
            if (!capitalize) return val;

            // Convert to string
            const strVal = String(val);

            // If it contains underscores, format each word
            if (strVal.includes("_")) {
                return strVal
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
            }

            // If it's a string (not just a number), capitalize first letter
            if (isNaN(val)) {
                return strVal.charAt(0).toUpperCase() + strVal.slice(1);
            }

            // Return original value for numbers
            return val;
        };
        return <div>{value ? formatValue(value) : ""}</div>;
    });

// Transfer type renderer with dropdown options
export const transferTypeRenderer = createRenderer(
    "TransferTypeRenderer",
    (rowData, field, { handleInputChange, isEditing, newRow, editingRow }) => {
        const value = rowData.isNew
            ? newRow?.[field] || ""
            : isEditing
              ? editingRow[field] || ""
              : rowData[field] || "";

        const formatValue = (val) => {
            return val
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        };

        // Display formatted version when not editing
        if (!isEditing) {
            return <div>{value ? formatValue(value) : ""}</div>;
        }

        // When editing, use dropdown with predefined options
        return (
            <div className={styles.statusCell}>
                <select
                    className={styles.statusSelect}
                    value={value}
                    onChange={(e) =>
                        handleInputChange(rowData, field, e.target.value)
                    }
                >
                    <option value="">Select type...</option>
                    {TRANSFER_TYPE_OPTIONS.map((type) => (
                        <option key={type} value={type}>
                            {formatValue(type)}
                        </option>
                    ))}
                </select>
                <span className={styles.dropdownIcon}>▼</span>
            </div>
        );
    },
);
